import React from 'react';
import classNames from 'classnames';

import isologoVenetoEdificios from '../assets/statics/logo-veneto-edificios.svg';

const Navbar =  (props) => {
  const { isTanks } = props;

  const navbarClass = classNames('navbar navbar-expand-lg navbar-dark', {
    isTanks
  });
  
  return (
    <nav className={navbarClass}>
      <div className="container">
        <a href="/" className="navbar-brand ">
          <img src={isologoVenetoEdificios} alt="Isologotipo Veneto Edificios"/>
        </a>
      </div>
    </nav>
  )
}

export default Navbar;