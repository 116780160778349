import React from "react";
import { Link as Scroll } from "react-scroll";

const Cta1 = (props) => (
  <div className="cta">
    <div className="container">
      <h3 className="mb-4">¿Listo para ser parte de esta experiencia?</h3>
      <h4 className="mb-4">Conocé nuestra propuesta...</h4>
      <Scroll
        to="form"
        spy={true}
        smooth={true}
        offset={-70}
        duration={599}
        className="btn btn-lg btn-danger text-white"
        >
        ¡Consultá ahora!
      </Scroll>
    </div>
  </div>
);

export default Cta1;
