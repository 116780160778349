import React from "react";

// import Carousel from 'react-bootstrap/Carousel';

import Form from './Form.js';

import isologotipoVeneto from "../assets/statics/logo-veneto-edificios.svg";


const Hero = (props) => (
  <div className="hero">
    <div className="darken"></div>
    <div className="content">
      <div className="container">
        <div className="row">
          <div className="bajada col-md-6 col-lg-7 text-center">
            <h2 className="pb-3 handwriting">Vacaciones de invierno 2021, en <br></br>Villa Carlos Paz</h2>
            <h5 className="lead pb-4">Valle de Punilla</h5>
            <img id="logo_empresa" className="mb-5" src={isologotipoVeneto} alt="logotipo Veneto Edificios"></img>
            <h1 className="mt-1">Alquiler temporario </h1>
            <h1 className="pb-4"><span>de departamentos equipados</span></h1>
            <h3 className="pb-1 handwriting">¡Viví una experiencia única!..</h3>
            <h4 className="pb-3">en el corazón de las sierras de Córdoba</h4>
          </div>
          <div className="col-md-6 col-lg-4 form"><Form /></div>
        </div>
      </div>
    </div>
  </div>
);

export default Hero;