import React from "react";
import { Link as Scroll } from "react-scroll";

const Cta2 = (props) => (
  <div className="cta cta-2">
    <div className="container">
      <h3 className="mb-4">¡Tenemos unidades disponibles!</h3>
      <h4 className="mb-4">Hacenos tu reserva...</h4>
      <Scroll
        to="form"
        spy={true}
        smooth={true}
        offset={-70}
        duration={599}
        className="btn btn-lg btn-danger text-white"
        >
        ¡Consultá ahora!
      </Scroll>
    </div>
  </div>
);

export default Cta2;
