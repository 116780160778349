import React from "react";

import Carousel from 'react-bootstrap/Carousel';

import imagenSlide01 from "../assets/statics/veneto-3/veneto-3-01.jpg";
import imagenSlide02 from "../assets/statics/veneto-3/veneto-3-02.jpg";
import imagenSlide03 from "../assets/statics/veneto-3/veneto-3-03.jpg";
import imagenSlide04 from "../assets/statics/veneto-3/veneto-3-04.jpg";
import imagenSlide05 from "../assets/statics/veneto-3/veneto-3-05.jpg";
import imagenSlide06 from "../assets/statics/veneto-3/veneto-3-06.jpg";
import imagenSlide07 from "../assets/statics/veneto-3/veneto-3-07.jpg";
import imagenSlide08 from "../assets/statics/veneto-3/veneto-3-08.jpg";
import imagenSlide09 from "../assets/statics/veneto-3/veneto-3-09.jpg";
import imagenSlide10 from "../assets/statics/veneto-3/veneto-3-10.jpg";
import imagenSlide11 from "../assets/statics/veneto-3/veneto-3-11.jpg";
import imagenSlide12 from "../assets/statics/veneto-3/veneto-3-12.jpg";
import imagenSlide13 from "../assets/statics/veneto-3/veneto-3-13.jpg";
import imagenSlide14 from "../assets/statics/veneto-3/veneto-3-14.jpg";
import imagenSlide15 from "../assets/statics/veneto-3/veneto-3-15.jpg";




const Product1 = (props) => (
    <div className="product">
        
        <div className="container">
            <div className="summary">
                <h2 className="mb-4"><span>Veneto III </span><small>Villa Carlos Paz</small></h2>
                <p>Un edificio dinámico, cerca y conectado con todo.</p>
                <p>Se destaca por su estratégica ubicación, vistas panorámicas y espacios amplios. Sus departamentos fueron creados para brindar experiencias únicas, con amenities cuidadosamente diseñados, para el disfrute y bienestar de quienes los habitan.</p> 
                <br></br>
                <h3 className="mb-5">Características distintivas</h3>
                <ul>
                    <li><span></span>Edificio a estrenar</li>
                    <li><span></span>A solo a unas cuadras de la peatonal y centro viejo</li>
                    <li><span></span>Emplazado en un barrio de gran tranquilidad</li>
                    <li><span></span>Vista 360° de Carlos Paz desde terraza</li>
                    <li><span></span>A pocas cuadras de Avenida Uruguay</li>
                    <li><span></span>Cercanía a supermercados</li>
                    <li><span></span>Cercanía al Casino</li>
                    <li><span></span>Estación de ejercicio y juegos infantiles a metros</li>
                    
                </ul>
            </div>
            
            <div className="slides">
                <Carousel>
                    <Carousel.Item><img src={imagenSlide01} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide02} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide03} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide04} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide05} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide06} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide07} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide08} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide09} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide10} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide11} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide12} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide13} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide14} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide15} alt="" loading="lazy"></img></Carousel.Item>
                </Carousel>
            </div>

            <div className="summary">
                <h3>Ubicación Geográfica</h3>
            </div>

            <div className="location">

                    <div className='row '>
                        <div className='col-12'>
                            <div className='mapa'>
                                <iframe title="mapa_ubicacion"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1702.4363668151311!2d-64.49938055908196!3d-31.417632310713433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d674598152aa9%3A0xfa0b885dd4741bfd!2sVENETO%203%20-%20Edificio%20Veneto%20III!5e0!3m2!1ses!2sar!4v1608582310988!5m2!1ses!2sar"
                                frameBorder='0'
                                allowFullScreen=''
                                aria-hidden='false'
                                tabIndex='0'
                                ></iframe>
                            </div>
                        </div>
                </div>
            </div>

        </div>


    </div>
);

export default Product1;
