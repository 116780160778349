import React, {useState, useEffect} from "react";

import ReactDOM from 'react-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp  } from '@fortawesome/free-brands-svg-icons';


const BtnWhatsApp = () => {


  const [fadeProp, setFadeProp] = useState({
    fade: 'whatsapp_block fade-out',
  });

  useEffect(() => {

    const timeout = setInterval(() => {
        
      setFadeProp({
          fade: 'whatsapp_block fade-in'
      })
    }, 4000);

    return () => clearInterval(timeout)
  }, [fadeProp])


  return ReactDOM.createPortal(
    <div className={fadeProp.fade}>
      <a id="btn_whatsapp_home_web"
        href="https://web.whatsapp.com/send?phone=5493533431541"
        className="whatsapp_float d-none d-md-block "
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <a id="btn_whatsapp_home_movil"
        href="https://api.whatsapp.com/send?phone=5493533431541"
        className="whatsapp_float d-block d-md-none "
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
    </div>
    ,document.getElementById('whats')
  );
}

export default BtnWhatsApp
