import React from "react";

// import HeroVenetoVillage from "../assets/statics/hero-veneto-village.jpg";


const Availables = (props) => (
    <div className="availables">
        <div>
            <div className="overlay"></div>
            <div className="content">
                <div className="container">
                    <div className="info">
                        <h1 className="big-title"><span>Complejos disponibles </span><small>para alquiler temporal</small></h1>
                    </div>
                </div>
            </div>
        </div>

    </div>
);

export default Availables;